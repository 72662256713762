import React from 'react';
import './Information.css';
import BaseLayout from '../../components/Base/BaseLayout';
import { useTranslation } from 'react-i18next';

function DeleteUserData() {
    const { t } = useTranslation();
    return (
        <BaseLayout>
            <div className="info-page">
                <div className="info-content">
                    <div className="info-text-container">
                        <h1 className="info-title">{t('Do you want your data deleted?')}</h1>
                        <p className="info-paragraph">
                        {t('If you want your data deleted from our databases, please contact ')} <a href="mailto:admin@bayzar.com" className="info-link">admin@bayzar.com</a> {t(' and provide your email login. Data should be deleted within 24 hours.')}
                        </p>
                    </div>
                </div>
            </div>
        </BaseLayout>
    );
}

export default DeleteUserData;
